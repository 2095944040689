import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => ( {
	dialogPaper: {
		display         : "flex",
		flexDirection   : "column",
		alignItems      : "center",
		width           : "45rem",
		height          : "22rem",
		// margin          : "2rem",
		borderRadius    : "10px",
		backgroundColor : theme.other.colors.dialog.primary,
		padding         : "2rem 0",
		justifyContent  : "space-evenly",
		position        : "relative"
	},
	dialogContent: {
		display        : "flex",
		flexDirection  : "column",
		padding        : "0 !important",
		alignItems     : "center",
		justifyContent : "center",
		textAlign      : "center",
		overflowY      : "hidden",
		"-webkit-flex" : "unset",
		background     : "none"
		// width          : "45rem"
	},
	title: {
		fontSize      : "1.8rem",
		fontWeight    : "500",
		marginBottom  : "0.5em",
		textTransform : "capitalize"
	},
	text: {
		fontSize: "1.5rem"
	},
	svgIcon: {
		width  : "6rem",
		height : "6rem"
	},
	svgIconCross: {
		position : "absolute",
		width    : "3.5rem",
		top      : "6%",
		right    : "3%",
		cursor   : "pointer"
	}
} ) );
