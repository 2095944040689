import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( theme => {

	return {
		customDialogContent: {
			backgroundColor : theme.other.colors.dialog.secondary,
			color           : theme.other.colors.text.primary,
			padding         : "2rem !important"
		},
		contentTextFirst: {
			fontWeight : "500",
			fontSize   : "1.5rem"
		},
		contentTextSecond: {
			color        : theme.other.colors.text.secondary,
			marginBottom : "0.5rem",
			fontSize     : "1.5rem"
		},
		reportButtonContainer: {
			display  : "flex",
			flexWrap : "wrap",
			margin   : "1.5rem 0 0 0"
		},
		reportButton: {
			margin: "0 1rem 1rem 0"
		},
		reportButtonText: {
			color         : theme.other.colors.text.primary,
			fontSize      : "1.3rem",
			fontWeight    : "500",
			textTransform : "none"
		},
		topHeader: {
			padding         : "1rem 2rem",
			display         : "flex",
			alignItems      : "center",
			justifyContent  : "space-between",
			borderBottom    : theme.other.darkMode ? "none" : `solid 2px ${ theme.other.colors.border.primary }`,
			backgroundColor : theme.other.colors.dialog.primary
		},
		settingsText: {
			fontWeight : "500",
			fontSize   : "1.7rem"
		},
		crossButton: {
			height : "3rem",
			width  : "3rem"
		},
		crossButtonInvert: {
			cursor    : "pointer",
			transform : "scale(0.7)",
			filter    : "invert(1)"
		},
		customDialog: {
			width           : "450px",
			minWidth        : "400px",
			height          : "max-content",
			flex            : "0 0 450px",
			backgroundColor : theme.other.colors.dialog.primary
		},
		modalBody: {
			padding: "0"
		},
		submitButton: {
			padding: "1rem 4rem",

			[rooterMediaQuery.mobile]: {
				width: "100%"
			}
		},
		submitButtonDeactive: {
			backgroundImage : "linear-gradient(to bottom, #126bd5, #439aff)",
			opacity         : "0.5",
			textTransform   : "none !important",
			boxShadow       : "none !important",
			borderRadius    : "10px",
			padding         : "1rem 4rem",

			[rooterMediaQuery.mobile]: {
				width: "100%"
			}

		},
		submitButtonContainer: {
			display        : "flex",
			justifyContent : "flex-end",
			padding        : "1rem",

			[rooterMediaQuery.mobile]: {
				backgroundColor: theme.other.colors.dialog.primary
			}
		},
		submitButtonText: {
			fontSize   : "1.3rem",
			fontWeight : "500"
		}
	};
} );
