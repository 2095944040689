import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => ( {
	mainContainer: {
		padding: "0.5rem"
	},
	title: {
		fontWeight : "500",
		lineHeight : 1.5,
		fontSize   : "1.2rem"
	},
	content: {
		marginTop : "0.5rem",
		fontSize  : "1.1rem"
	},
	listContainer: {
		listStylePosition: "inside"
	},
	listElement: {
		fontSize  : "1.1rem",
		color     : theme.other.colors.text.primary,
		marginTop : "1rem"
	}
} ) );
