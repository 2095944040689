import { useEffect, useState } from "react";

import { useGetDarkMode }            from "_common/hooks/global";
import Toast                         from "_common/toast";
import { LIVESESSION }               from "_common/utils";
import { reportSession }             from "_services/api/broadcast";
import { getReportTags, reportPost } from "_services/api/feed";

import Presentation from "./presentation";

const component = ( { feedId, setShowDialog, verticalFeedType, feedType, showDialog, updateFeedList } ) => {

	const [ tagList, setTagList ]               = useState ( [] );
	const [ selectedOption, setSelectedOption ] = useState ( null );
	const darkMode                              = useGetDarkMode ();
	const dummyData                             = new Array ( 6 ).fill ( 0 );

	// onMount calling Api function
	useEffect ( () => {
		getTags ();
	}, [] );

	const setTimer = feedId => {
		setTimeout ( () => {
			updateFeedList ( {
				id        : feedId,
				isRemoved : true
			} );
	   }, 200000000 );
	};

	// Api call for report tags
	const getTags = async () => {
		try {
			const list = await getReportTags ();

			setTagList ( list );
		} catch ( err ) {
			return Toast.error ( err.displayMsg );
		}
	};

	// handler for selecting a report buttonComponent
	const handleReport = e => {
		if ( selectedOption === e ) {
			setSelectedOption ( null );
		} else {
			setSelectedOption ( e );
		}

	};

	// handler for submitting form button
	const handleSubmit = async () => {
		if ( !selectedOption ) return;
		const params = {
			feedId     : parseInt ( feedId ),
			reasonId   : selectedOption.id,
			reasonType : selectedOption.reasonType

		};

		try {

			if ( feedType === LIVESESSION || feedType === "broadcast" ) {
				const data = await reportSession ( params );
				/* const payload = {
				 *     params,
				 *     verticalFeedType
				 * }; */

				if ( data === "success" ) {
					// condition for only reporting broadcast session and not removing from feed list
					if ( verticalFeedType === "report-only" ) {
						setShowDialog ( false );
						Toast.info ( "Reported Successfully" );

						return;
					}
					updateFeedList ( { type: "report" } );

					setTimer ( params.feedId );
					setShowDialog ( false );
				}
			} else {
				const data = await reportPost ( params );

				/*
 *                 const payload = {
 *                     params,
 *                     verticalFeedType
 *                 };
 *  */
				if ( data.status === "SUCCESS" ) {
					// condition for only reporting static feed posts and not removing from feed list
					if ( verticalFeedType === "report-only" ) {
						setShowDialog ( false );
						Toast.info ( "Reported Successfully" );

						return;
					}
					updateFeedList ( { type: "report" } );

					setTimer ( params.feedId );
					setShowDialog ( false );
				}
			}

		} catch ( err ) {
			setShowDialog ( false );
			if ( err.code === 409 ) {
				return Toast.error ( err.displayMsg );
			} else {
				return Toast.error ( "Couldn't report, try again later" );
			}
		}
	};

	return (
		<Presentation
			darkMode      = { darkMode }
			dummyData     = { dummyData }
			handleReport  = { handleReport }
			handleSubmit  = { handleSubmit }
			selected      = { selectedOption }
			setShowDialog = { setShowDialog }
			showDialog    = { showDialog }
			tagList       = { tagList }
		/>
	);
};

export default component;
