import dayjs from "dayjs";

import logger                       from "_common/logger";
import Toast                        from "_common/toast";
import { getModeratorsList }        from "_services/api/broadcast";
import { getUserProfileFromBranch } from "_services/api/user";

import { initialData } from "./states";

const log = logger ( { file: "common/utils/rtmp" } );

export const formatTagsData = tagsList => {

	if ( !tagsList?.length ) return [];

	return tagsList.map ( tag => {
		return {
			tagId       : tag?.tagId,
			displayName : tag?.tagName || tag?.displayName
		};
	} );
};

export const isSameDate = givenDate => {
	const currentDate = dayjs ();

	givenDate = dayjs ( new Date ( givenDate ) );

	const dif = currentDate.diff ( givenDate, "day" );

	return dif === 0;
};

export const formatStreamToolsData = ( tools, existingData ) => {
	let streamDelay = {},
		boostStream = {},
		uploadThumbnail = {};

	const boostStreamExistingData = existingData && existingData.boostStream;
	const streamDelayExistingData = existingData && existingData.streamDelay;

	tools.map ( tool => {
		if ( tool.type === "STREAM_DELAY" ) {
			let selectedOption = streamDelayExistingData && streamDelayExistingData.name === "streamDelay" ? streamDelayExistingData.selectedOption : null,
				status = streamDelayExistingData && streamDelayExistingData.name === "streamDelay" ? streamDelayExistingData.status : false;
			const options      = tool.levels.map ( ( level, index ) => {
				if ( level.enabled === 1 && existingData ) {
					if ( !existingData || ( streamDelayExistingData && streamDelayExistingData.name !== "streamDelay" ) ) {
						selectedOption = index;
						status         = true;
					}
				} else if ( level.enabled === 1 ) {
					status = true;
					if ( selectedOption === null ) selectedOption = index;
				}

				return {
					id          : level.id,
					value       : level.coins,
					description : `${ level.duration } Seconds`,
					purchased   : level.enabled === 1,
					duration    : level.totalDuration
				};
			} );

			if ( !selectedOption ) selectedOption = 0;
			streamDelay = {
				name    : "streamDelay",
				id      : tool.id,
				options : [ ...options ],
				selectedOption,
				status
			};
		}

		if ( tool.type === "BOOST_STREAM" ) {
			let selectedOption = boostStreamExistingData && boostStreamExistingData.name === "boostStream" ? boostStreamExistingData.selectedOption : 0,
				status = boostStreamExistingData && boostStreamExistingData.name === "boostStream" ? boostStreamExistingData.status : false;
			const options      = tool.levels.map ( ( level, index ) => {
				if ( level.enabled === 1 && existingData ) {
					if ( !boostStreamExistingData || ( boostStreamExistingData && boostStreamExistingData.name !== "boostStream" ) ) {
						selectedOption = index;
						status         = true;
					}
				}
				const val = ( level.totalDuration / 3600 ).toFixed ( 2 );

				return {
					id            : level.id,
					value         : level.coins,
					description   : `${ val } ${ val <= 1 ? "Hour" : "Hours" }`,
					purchased     : level.enabled === 1,
					duration      : level.duration,
					totalDuration : level.totalDuration
				};
			} );

			boostStream = {
				name    : "boostStream",
				id      : tool.id,
				options : [ ...options ],
				selectedOption,
				status
			};
		}

		if ( tool.type === "UPLOAD_THUMBNAIL" ) {
			uploadThumbnail = {
				name      : "uploadThumbnail",
				id        : tool.id,
				purchased : tool.enabled,
				value     : tool.coins
			};
		}
	} );

	return { streamDelay, boostStream, uploadThumbnail };
};

export const getBoostData = boostData => {
	if ( boostData && boostData.options ) {
		let enabled       = false;
		let remainingTime = -1;

		boostData.options.forEach ( item => {
			if ( item.purchased ) {
				remainingTime = Math.max ( item.duration, remainingTime );
				enabled       = true;
			}
		} );

		return { remainingTime, enabled };
	}
};

export const checkIfItemPurchased = ( tool, level ) => {
	let purchased = false;

	const checkLevel = ( idx, ele ) => {
		if ( ( ( ( level || level === 0 ) && idx === level ) || ( !level && level !== 0 ) ) && ele.purchased )
			return true;
	};

	if ( tool ) {
		switch ( tool.name ) {
			case "thumbnail":
				if ( tool.src ) purchased = true;
				break;

			case "saveToProfile":
				break;

			default:
				tool?.options?.forEach (
					( ele, idx ) => {
						purchased = checkLevel ( idx, ele );
					}
				);
				break;
		}
	}

	return purchased;
};

export const isValidStream = ( { sessionData, userId } ) => {
	if ( !sessionData || !userId ) return false;

	const isOwnStream = userId === sessionData.broadcaster.sportsFan.id;

	if ( !isOwnStream || sessionData.hasEnded ) return false;

	return true;
};

export const isFromPopularGames = ( selectedGame = {}, sortedGamesList, popularGamesCount = 5 ) => {
	if ( !Object.keys ( selectedGame ).length ) return false;

	const idx = sortedGamesList.findIndex ( game => game.id === selectedGame.id );

	return idx < popularGamesCount;

};

export const getSortedGamesByPopularity = ( gamesList = [] ) =>
	[ ...gamesList ].sort ( ( a, b ) => parseFloat ( b.currentStreamCount ) - parseFloat ( a.currentStreamCount ) );

export const fetchModerators = async params => {
	const { pageNo } = params;

	const res = await getModeratorsList ( { pageNo, pageSize: 10 } );

	return res;
};

export const getModeratorsCount = moderatorsList => {
	if ( !moderatorsList?.length ) return 0;

	return moderatorsList.reduce ( ( acc, usr ) => {
		if ( !usr.isRemoved ) {
			acc = acc + 1;

			return acc;
		}

		return acc;
	}, 0 );
};

export const getVideoTypeFromDuration = duration => {
	if ( duration > 60 ) {
		return "video";
	}

	return "reel";
};

export const getVideoDuration = file => {
	return new Promise ( ( resolve, reject ) => {
		try {
			const video = document.createElement ( "video" );

			const dataURL = URL.createObjectURL ( file );

			video.src = dataURL;

			video.onloadedmetadata = () => {
				resolve ( video.duration );
			};

			video.onerror = error => {
				reject ( error );
			};
			video.type    = file.type;
		} catch ( error ) {
			reject ( error );
		}
	} );
};

const getUserIdFromURL = url => {
	const prefix = "/profile/";

	return url.split ( prefix )[1];
};

export const getUserIdFromProfileLink = async link => {
	if ( !link ) return "";

	if ( link.includes ( "rooter.app.link" ) ) {
		const response = await getUserProfileFromBranch ( { url: link } );

		return response.userId;
	}

	return getUserIdFromURL ( link );

};

export const isValidatedCreateLiveParams = params => {
	if ( !params ) {
		Toast.error ( "Please enter required details to go live" );
		log.fatal ( "No parameter provided in isValidatedCreateLiveParams function call" );

		return false;
	}

	let isValidated = true, errorMessage;

	if ( !params?.streamDetails?.title ) {
		isValidated  = false;
		errorMessage = "Stream title is required";
	}

	if ( !params?.streamDetails?.game?.androidPackageName ) {
		isValidated  = false;
		errorMessage = "Please select a game";
	}

	if ( !params?.rtmpDetails?.streamKey || !params?.rtmpDetails?.cdnUrl ) {
		isValidated  = false;
		errorMessage = "Stream preview is not available";
	}

	if ( !params?.streamDetails?.game?.name ) {
		isValidated  = false;
		errorMessage = "Please select a game";
	}

	if ( !isValidated ) {
		log.error ( { params }, "Missing parameters in isValidatedCreateLiveParams function call" );
		Toast.error ( errorMessage );

		return false;
	}

	log.info ( { params }, "Received all parameters in isValidatedCreateLiveParams function call" );

	return true;
};

export const formatStudioData = ( rtmpDetails, settings, languagesList ) => {

	const isLive              = settings?.isLive;
	const streamSetting       = settings?.streamSetting && Object.keys ( settings?.streamSetting ).length ? true : false;
	const thumbnailDetails    = streamSetting ? settings?.streamSetting?.thumbnailDetails : initialData?.thumbnailDetails;
	const donation            = streamSetting ? settings?.streamSetting?.donationGoal : initialData?.donationGoal;
	let followerOnlyChat      = streamSetting ? settings?.streamSetting?.isFollowerOnlyChat : initialData?.isFollowerOnlyChat;
	let donorOnlyChat         = streamSetting ? settings?.streamSetting?.isDonorOnlyChat : initialData?.isDonorOnlyChat;
	let recordStream          = streamSetting ? settings?.streamSetting?.recordStream : initialData?.recordStream;
	const fanLeaderboard      = settings?.fanLeaderboard;
	let sameDayFanLeaderBoard = !!fanLeaderboard;
	let fanRank               = initialData?.fanRank;
	let rtmp                  = initialData?.rtmpDetails;
	const selectedOption      = settings?.audienceVisibility?.find ( e => e?.selected === true );
	let audienceVisibility    = selectedOption?.id.toString ();

	let streamDetails = streamSetting ? settings?.streamSetting?.streamDetails : initialData?.streamDetails;
	const localeKey   = streamSetting ? streamDetails?.streamLanguage?.localeKey : null;
	let tagsList      = formatTagsData ( streamDetails?.tagsList || [] );
	let streamLanguage;

	if ( localeKey ) {
		streamLanguage = languagesList && languagesList.find ( data => data.localeKey === localeKey );

		streamDetails = { ...streamDetails, streamLanguage };
	}

	if ( streamSetting ) {
		streamDetails = { ...streamDetails, tagsList };
	}

	if ( rtmpDetails?.rtmp ) {
		rtmp = {
			rtmpUrl      : rtmpDetails.rtmp,
			streamKey    : rtmpDetails.streamKey,
			cdnUrl       : rtmpDetails.cdn,
			streamName   : rtmpDetails.cssStreamName,
			ivsChannelId : rtmpDetails.ivsChannelId
		};
	}

	//  if user enabled fanLeaderBoardLive then enable it for the whole day
	if ( !isLive ) {
		fanRank = {
			fanRankAmount : fanLeaderboard,
			fanRankStatus : !!fanLeaderboard
		};

		sameDayFanLeaderBoard = !!fanLeaderboard;
	}

	return {
		...initialData,
		rtmpDetails           : rtmp,
		streamDetails         : streamDetails,
		thumbnailDetails      : thumbnailDetails,
		fanRank               : fanRank,
		donationGoal          : donation,
		sameDayFanLeaderBoard : sameDayFanLeaderBoard,
		audienceVisibility    : audienceVisibility,
		recordStream          : parseInt ( recordStream ),
		isFollowerOnlyChat    : !!followerOnlyChat,
		isDonorOnlyChat       : !!donorOnlyChat,
		fromLastSession       : streamSetting ? true : false
	};
};
