import { Modal } from "@mantine/core";
import React     from "react";

import Icon       from "_components/atoms/icon";
import Typography from "_components/atoms/typography";
import useStyles  from "_styles/molecules/infoModal";

const InfoModal = ( {
	buttonComponent, dialogText,
	rtmpPage, from, fromHomePage, dialogTitle = "", open, setOpen, mainIcon, cancelIcon
} ) => {
	const { classes } = useStyles ( undefined, { name: "styles" } );

	const handleClose = () => {
		setOpen ( false );
	};

	return (
		<div style = { { flex: rtmpPage ? "2 1 auto" : "none", width: from == "userInfoPopOver" ? "48%" : fromHomePage ? "100%" : "auto" } }>
			<div
				style = { { width: from == "userInfoPopOver" ? "100%" : "" } }
			>
				{buttonComponent}
			</div>

			<Modal
				centered
				aria-describedby = "alert-dialog-description"
				aria-labelledby  = "alert-dialog-title"
				classNames       = { {
					content : classes.dialogContent,
					body    : classes.dialogPaper
				} }
				onClose          = { handleClose }
				opened           = { open }
				radius           = "lg"
				withCloseButton  = { false }
			>
				<Icon
					className = { classes.svgIconCross }
					name      = { cancelIcon }
					onClick   = { handleClose }
				/>

				<Icon
					className = { classes.svgIcon }
					name      = { mainIcon }
				/>

				{dialogTitle ? (
					<Typography
						className = { classes.title }
						title     = { "okokokoksdgoj sdofjnsodkngosdkn" }
					/>
				) : null}

				<Typography
					className = { classes.text }
					color     = "secondary"
					title     = { "title dlfnslidnglsirnlin" }
					// variant = "body1"
				/>
			</Modal>
		</div>
	);
};

export default InfoModal;
