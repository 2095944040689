import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( ( theme, props ) => ( {
	dotsIcon: {
		width                     : props?.from === "reel" ? "3.8rem" : "2.25rem",
		[rooterMediaQuery.mobile] : {
			width: props?.from === "reel" ? "3.2rem" : "2.25rem"
		},
		// height       : props?.from === "reel" ? "3.8rem" : "2.25rem",
		cursor       : "pointer",
		background   : props?.open ? theme.other.colors.background2.primary : "",
		borderRadius : props?.open ? "100%" : "",
		transform    : props?.from === "reel" ? "rotate(90deg)" : "unset",
		padding      : "2px",

		"&:hover": {
			backgroundColor : theme.other.colors.background2.primary,
			borderRadius    : "100%"
		}
	},
	dotsIconWrapper: {
		display        : "flex",
		alignItems     : "center",
		alignSelf      : "baseline",
		justifyContent : "center",
		borderRadius   : "50%",

		[ rooterMediaQuery.desktop ]: {
			...( props?.from === "reel" && { background: theme.other.paletteNew.darkModeDarkGrey20 } )
		}
	},
	svgIcon: {
		cursor : "pointer",
		width  : "25px"
	},
	iconText: {
		fontSize    : "1.6rem",
		paddingLeft : "1rem",
		cursor      : "pointer",
		fontWeight  : "500",

		[rooterMediaQuery.mobile]: {
			paddingLeft: "2rem"

		}
	},
	popperStyle: {
		padding      : "0.75rem",
		borderRadius : "5px",
		boxShadow    : theme.other.colors.boxShadow.primary,

		[rooterMediaQuery.mobile]: {
			borderRadius: "10px 10px 0 0"
		}
	},
	popperWrapper: {
		zIndex: props?.from === "image-modal" ? "102" : "100"
	},
	dropdown: {
		display         : "flex",
		alignItems      : "center",
		justifyContent  : "center",
		backgroundColor : "transparent",
		border          : "none",
		padding         : "0",
		boxShadow       : theme.other.colors.boxShadow.primary
	},
	menuItem: {
		display      : "flex",
		alignItems   : "center",
		padding      : "0.5rem",
		borderRadius : "5px",
		cursor       : "pointer",
		"&:hover"    : {
			backgroundColor: theme.other.darkMode ? theme.other.paletteNew.secondaryBlack50 : theme.other.paletteNew.darkModeDarkGrey20
		},

		[rooterMediaQuery.mobile]: {
			padding: "1rem 1.2rem"
		}
	},
	menuItemInfoModal: {
		opacity      : props?.status == "PENDING" || props?.status == "PROCESSING" ? "0.5" : "1",
		display      : "flex",
		alignItems   : "center",
		padding      : "0.5rem",
		borderRadius : "5px",
		cursor       : "pointer",
		"&:hover"    : {
			backgroundColor: "rgba(0, 0, 0, 0.04)"
		}
	},
	followComponent: {
		display      : "flex",
		alignItems   : "center",
		borderRadius : "5px"
	},
	loaderWrapper: {
		width      : props?.status === "NO_DOWNLOAD_REQUESTED" || props?.status === "FAILED" ? "19rem" : props?.status === "PROCESSING" || props?.status === "PENDING" ? "20rem" : "15rem",
		padding    : "0 !important",
		background : "none"
	},
	rectangularSekleton: {
		margin : "0 10px",
		width  : "70%"
	},
	profileSvgIcon: {
		width  : "2rem",
		cursor : "pointer"
	},
	profileInfoIcon: {
		width  : "1.5rem",
		cursor : "pointer"
	},
	hoverInfoText: {
		display: "flex"
	},
	moderatorContainer: {
		display: "flex"
	}
} ) );